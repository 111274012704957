<template>
  <vertical-tabs-component
    :tabs="tabs"
    :isRouterContent="true"
  ></vertical-tabs-component>
</template>

<script>
import VerticalTabsComponent from "@/components/vertical-tabs/VerticalTabsComponent";
import PerfilHelpers from "@/helpers/PerfilHelpers";


export default {
  components: {
    VerticalTabsComponent
  },
  data() {
    return {
      profileHelpers: PerfilHelpers
    };
  },
  computed: {
    tabs() {
      let tabs = [];

      if (
        this.profileHelpers.checkPermission([
          this.profileHelpers.menuFuncionalitiesEnum.VISUALIZAR_USUARIOS,
          this.profileHelpers.menuFuncionalitiesEnum.GERENCIAR_USUARIOS
        ])
      )
        tabs.push({
          label: "Usuários",
          tab: "lista-usuarios",
          router: { name: "lista-usuarios" }
        });

      if (
        this.profileHelpers.checkPermission([
          this.profileHelpers.menuFuncionalitiesEnum.GERENCIAR_PERFIS
        ])
      )
        tabs.push({
          label: "Perfis de acesso",
          tab: "lista-perfis",
          router: { name: "lista-perfis" }
        });

      return tabs;
    }
  },
  created() {
    this.$appInsights.trackPageView({
      name: "usuarios",
      url: window.location.href
    });
  }
};
</script>

<style></style>
